<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import axios from "axios";
import Swal from "sweetalert2";
import Pagination from "@/router/layouts/table-pagination.vue";
import Datatable from "@/router/layouts/table-datatable.vue";

export default {
  page: {
    title: "Kategori Studi Kasus",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    Pagination,
    Datatable,
  },
  data() {
    let sortOrders = [];
    let columns = [
      { width: "50px", label: "No", name: "-" },
      { width: "auto", label: "Studi Kasus", name: "kategori" },
      { width: "150px", label: "Aksi", name: "-" }, // jika tidak ada sorting kasih name dengan karakter -
    ];
    columns.forEach((column) => {
      sortOrders[column.name] = -1;
    });
    const menu = "Kategori Studi Kasus";
    return {
      title: menu,
      isWrite: localStorage.getItem("isWrite"),
      isRead: localStorage.getItem("isRead"),
      items: [
        {
          text: "Master Kompetensi",
          href: "/",
        },
        {
          text: "Kategori Studi Kasus",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      modalShow: false,
      loadingTable: false,
      tipeModal: "",
      dataEdit: null,
      columns: columns,
      sortKey: "nama_jabatan",
      sortOrders: sortOrders,
      listKatagoriStudiKasus: [],
      tableFilter: {
        status: 1,
        search: "",
        per_page: 10,
        page: 0,
        studiKasus: "",
      },
      paginationKatagoriStudiKasus: {
        total: "",
        from: "",
        to: "",
        page: "",
        per_page: "",
        links: "",
        lastPageUrl: "",
        nextPageUrl: "",
        prevPageUrl: "",
      },
      modalImport: false,
      kodeKategori: "",
      namaKategori: "",
    };
  },
  async mounted() {
    await this.getKategoriStudiKasus();
  },
  methods: {
    async getKategoriStudiKasus() {
      this.loadingTable = true;
      const data = await axios({
        url: process.env.VUE_APP_BACKEND_URL_VERSION + "master/kategori",
        params: this.tableFilter,
        headers: {
          Authorization: "Bearer " + localStorage.access_token,
        },
      })
        .then((response) => response.data)
        .catch((error) => {
          console.log(error);
          return false;
        });

      if (data) {
        this.listKatagoriStudiKasus = data.data.data;
        this.paginationKatagoriStudiKasus.total = data.data.total;
        this.paginationKatagoriStudiKasus.from = data.data.from;
        this.paginationKatagoriStudiKasus.to = data.data.to;
        this.paginationKatagoriStudiKasus.links = data.data.links;
        this.paginationKatagoriStudiKasus.lastPageUrl = data.last_page_url;
        this.paginationKatagoriStudiKasus.nextPageUrl = data.next_page_url;
        this.paginationKatagoriStudiKasus.prevPageUrl = data.prev_page_url;
        this.paginationKatagoriStudiKasus.per_page = this.tableFilter.per_page;
        this.paginationKatagoriStudiKasus.page = this.tableFilter.page;
        // console.log(this.listKatagoriStudiKasus);
        console.table(data.data.data);
      }
      this.loadingTable = false;
    },
    async postKategoriStudiKasus() {
      const body = {
        kode: this.kodeKategori,
        kategori: this.namaKategori,
        created_by: "",
      };

      Swal.fire({
        icon: "warning",
        title: "Mohon tunggu",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      await axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION + "master/kategori",
          body,
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Data berhasil disimpan",
            timer: 2000,
            showConfirmButton: false,
          });
          console.log(response);
          this.getKategoriStudiKasus();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Data gagal disimpan",
            timer: 1500,
            showConfirmButton: false,
          });
          console.error(error);
        });
    },
    async putKategoriStudiKasus(data) {
      const body = {
        kode: this.kodeKategori,
        kategori: this.namaKategori,
        active_flag: data.active_flag,
      };

      Swal.fire({
        icon: "warning",
        title: "Mohon tunggu",
        allowOutsideClick: false,
        showConfirmButton: false,
      });
      await axios
        .put(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/kategori/" +
            data.idkategori,
          body,
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          Swal.fire({
            icon: "success",
            title: "Data berhasil diubah",
            timer: 2000,
            showConfirmButton: false,
          });
          console.log(response);
          this.getKategoriStudiKasus();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Data gagal diubah",
            timer: 1500,
            showConfirmButton: false,
          });
          console.error(error);
        });
    },
    async disableKategoriStudiKasus(data) {
      const body = {
        kode: data.kode,
        kategori: data.kategori,
        active_flag: data.active_flag === 1 ? 0 : 1,
      };
      await axios
        .put(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/kategori/" +
            data.idkategori,
          body,
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
            },
          }
        )
        .then((response) => {
          if (data.active_flag === 0) {
            Swal.fire({
              icon: "success",
              title: "Data berhasil enable",
              timer: 1500,
              showConfirmButton: false,
            });
          } else if (data.active_flag === 1) {
            Swal.fire({
              icon: "success",
              title: "Data berhasil disable",
              timer: 1500,
              showConfirmButton: false,
            });
          }
          console.log(response);
          this.getKategoriStudiKasus();
        })
        .catch((error) => {
          if (data.active_flag === 0) {
            Swal.fire({
              icon: "error",
              title: "Data gagal enable",
              timer: 1500,
              showConfirmButton: false,
            });
          } else if (data.active_flag === 1) {
            Swal.fire({
              icon: "error",
              title: "Data berhasil disable",
              timer: 1500,
              showConfirmButton: false,
            });
          }
          console.error(error);
        });
    },
    modalTambahEdit(type, data) {
      this.tipeModal = type;
      this.$nextTick(() => {
        if (type == "edit") {
          (this.kodeKategori = data.kode),
            (this.namaKategori = data.kategori),
            (this.dataEdit = data),
            (this.modalShow = true);
        } else if (type == "add") {
          (this.kodeKategori = ""),
            (this.namaKategori = ""),
            (this.modalShow = true);
        }
      });
    },
    save(type) {
      if (type == "add") {
        this.postKategoriStudiKasus();
      } else if (type == "edit") {
        this.putKategoriStudiKasus(this.dataEdit);
      }
      this.modalShow = false;
    },
    toPage(url) {
      let link = new URLSearchParams(url);
      this.tableFilter.page = link.get("page");
      this.getKategoriStudiKasus();
    },
    sortBy(key) {
      this.sortKey = key;
      this.sortOrders[key] = this.sortOrders[key] * -1;
      this.tableFilter.column = key;
      this.tableFilter.dir = this.sortOrders[key] === 1 ? "asc" : "desc";
      this.getKategoriStudiKasus();
    },
    async downloadTemplate() {
      let url =
        process.env.VUE_APP_BACKEND_URL_VERSION +
        "download-template/kategori-studi-kasus";
      window.open(url, "_blank");
    },
    async importData() {
      this.importFile = this.$refs.file.files[0];

      Swal.fire({
        icon: "warning",
        title: "Mohon tunggu",
        allowOutsideClick: false,
        showConfirmButton: false,
      });

      await axios
        .post(
          process.env.VUE_APP_BACKEND_URL_VERSION +
            "master/kategori/import-excel",
          {
            file: this.importFile,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.access_token,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          console.log(response.data);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Create data berhasil",
            timer: 1000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          });
        })
        .catch((err) => {
          console.log(err);
          Swal.fire({
            icon: "warning",
            title: "Terjadi Kesalahan",
            timer: 3000,
            timerProgressBar: true,
            showCancelButton: false,
            showConfirmButton: false,
          });
        });

      this.modalImport = false;
      this.$refs.file.files = null;
      this.getKategoriStudiKasus();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between gap-2 mb-2">
              <div class="card-title">Daftar Kategori Studi Kasus</div>
              <div>
                <button
                  class="btn btn-success btn-sm mx-2"
                  @click="modalImport = true"
                  v-if="isWrite == 1"
                >
                  <i class="fa fa-file-excel me-1"></i>
                  Import Excel
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  @click="modalTambahEdit('add')"
                  v-if="isWrite == 1"
                >
                  <i class="fa fa-plus me-1"></i>
                  Tambah Data
                </button>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="form-group col-md-2">
                <label for="filterStudiKasus">Studi Kasus</label>
                <input
                  type="text"
                  id="filterStudiKasus"
                  class="form-control form-control-sm"
                  placeholder="Filter Studi Kasus"
                  v-model="tableFilter.studiKasus"
                />
              </div>
              <div class="form-group col-md-2">
                <label for="statusTable">Status</label>
                <select
                  class="form-control form-control-sm"
                  id="statusTable"
                  v-model="tableFilter.status"
                >
                  <option value="1">ENABLE</option>
                  <option value="0">DISABLE</option>
                </select>
              </div>
              <div class="form-group col-md-2 d-flex align-items-end">
                <div>
                  <button
                    class="btn btn-primary btn-sm"
                    @click="getKategoriStudiKasus()"
                  >
                    <i class="fa fa-search me-1"></i>Filter
                  </button>
                </div>
              </div>
            </div>
            <hr />
            <table class="mb-2">
              <tr>
                <td>Show</td>
                <td>
                  <select
                    class="form-control form-control-sm"
                    id="showpaginatetable"
                    v-model="tableFilter.per_page"
                    @change="getKategoriStudiKasus()"
                  >
                    <option value="10">10</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="200">200</option>
                    <option value="500">500</option>
                  </select>
                </td>
                <td>Entries</td>
              </tr>
            </table>
            <div class="table-responsive mb-0">
              <datatable
                :columns="columns"
                :sortKey="sortKey"
                :sortOrders="sortOrders"
                @sort="sortBy"
              >
                <tbody>
                  <tr v-if="loadingTable">
                    <td colspan="6">
                      <i class="fas fa-spinner fa-spin"></i> Loading...
                    </td>
                  </tr>
                  <tr v-else-if="listKatagoriStudiKasus == ''">
                    <td class="text-center" colspan="6">Data Tidak Tersedia</td>
                  </tr>
                  <tr
                    v-else
                    v-for="(rowForm, index) in listKatagoriStudiKasus"
                    :key="index"
                  >
                    <td class="text-center">
                      {{ paginationKatagoriStudiKasus.from + index }}
                    </td>
                    <td>{{ rowForm.kategori }}</td>
                    <td class="text-center">
                      <div
                        class="btn-group"
                        v-if="isWrite == 1"
                      >
                        <button
                          v-if="rowForm.active_flag === 1"
                          type="button"
                          class="btn btn-primary btn-sm"
                          @click="modalTambahEdit('edit', rowForm)"
                        >
                          <i class="fas fa-edit"></i> Edit
                        </button>
                        <button
                          v-if="rowForm.active_flag === 1"
                          type="button"
                          class="btn btn-danger btn-sm"
                          @click="disableKategoriStudiKasus(rowForm)"
                        >
                          <i class="fas fa-trash"></i> Disable
                        </button>
                        <button
                          v-else-if="rowForm.active_flag === 0"
                          type="button"
                          class="btn btn-success btn-sm"
                          @click="disableKategoriStudiKasus(rowForm)"
                        >
                          <i class="fas fa-check"></i> Enable
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </datatable>
            </div>
            <Pagination
              :pagination="paginationKatagoriStudiKasus"
              @to-page="toPage"
            ></Pagination>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
  <b-modal v-model="modalShow" title="Tambah Kategori Soal" hide-footer>
    <b-form class="p-0" @submit.prevent="save(this.tipeModal)">
      <b-form-group
        label="Nama Kategori Soal"
        label-for="nama_kategori"
        :state="!namaKategori ? false : null"
        feedback="Nama Kategori Soal harus diisi"
      >
        <b-form-input
          id="nama_kategori"
          v-model="namaKategori"
          required
        ></b-form-input>
      </b-form-group>
      <button type="submit" class="btn btn-primary float-end">Simpan</button>
    </b-form>
  </b-modal>

  <b-modal
    v-model="modalImport"
    title="Import Kategori Studi Kasus"
    size="md"
    hide-footer
  >
    <div class="row">
      <div class="col-12 mb-2">
        <button class="btn btn-success btn-sm" @click="downloadTemplate()">
          <i class="fa fa-download me-1"></i> Download Template
        </button>
      </div>
      <div class="col-12 mb-2">
        <div class="form-group">
          <label for="file_import_excel">File Excel</label>
          <input
            type="file"
            id="file_import_excel"
            class="form-control"
            ref="file"
            accept=".xlsx,.xls"
            mime
          />
        </div>
      </div>
      <div class="col-12">
        <button class="btn btn-primary btn-sm float-end" @click="importData()">
          <i class="fa fa-save me-1"></i> Save
        </button>
      </div>
    </div>
  </b-modal>
</template>
